import logo from '../styles/images/logo.png';
import Facebook from '../styles/images/facebook.png';
import Twitter from '../styles/images/twitter.png';
import Instagram from '../styles/images/instagram.png';
import { Link } from 'react-router-dom';

export default function footer() {
    return (
        <>
<footer className="Footer px-4 pb-5 pt-5" id="footer">
<div className="container-fluid px-4">
    <div className="row">
        <div className="col-12 col-md-3 mb-5 mb-md-0">
            <img src={logo} width="120"/>
        </div>
        <div className="col-12 col-md-2 mb-5 mb-md-0">
            <p className="mb-3 text-muted">Products</p>    
            <ul className='ps-0'>
                <li><Link to="NativetalkPersonal" className='text-black text-decoration-none'>Nativetalk Personal</Link></li>
                <li><Link to="NativetalkBusinesses" className='text-black text-decoration-none'>Nativetalk Business</Link></li>
                <li><Link to="NativetalkEnterprise" className='text-black text-decoration-none'>Nativetalk Enterprise</Link></li>
            </ul>
        </div>
        <div className="col-6 col-md-2 mb-5 mb-md-0">
            <p className="mb-3 text-muted">Plans and Pricing</p>    
            <ul className='ps-0'>
                <li><Link to="SelectPlan" className='text-black text-decoration-none'>Plans and Pricing</Link></li>                
            </ul>
        </div>
        <div className="col-12 col-md-2 mb-5 mb-md-0">
            <p className="mb-3 text-muted">Resources</p>    
            <ul className='ps-0'>
                <li><Link to="https://qa-forum.nativetalkapp.com" className='text-black text-decoration-none'>Q&A Forum</Link></li>
                <li><Link to="/AboutUs" className='text-black text-decoration-none'>About Us</Link></li>
            </ul>
        </div>
    
        <div className="col-6 col-md-2">
            <p className="mb-3 text-muted">Legal</p>    
            <ul className='ps-0'>
                <li><Link to="PrivacyPolicy" className='text-black text-decoration-none'>Privacy Policy</Link></li>
                <li><Link to="TermsAndConditions" className='text-black text-decoration-none'>Terms and Conditions</Link></li>
            </ul>
        </div>
    </div>
</div>
<div className='d-flex mt-5 pt-5 flex-column align-items-center'>
    <hr className="footer-line mx-5"/>
    <div className=" d-flex socials justify-content-between align-items-center  flex-row">
        <p className="text-muted my-0" style={{'fontSize': 'small'}}>&copy; 2024 NativeTalk. All rights reserved</p>
        <div >
            <img src={Facebook} alt="" className="media-icon"/>
            <a target='_blank' href="https://www.twitter.com/nativetalk_io"><img src={Twitter} alt="" className="media-icon"/></a>
            <a target='_blank' href='https://www.instagram.com/nativetalk.io/'><img src={Instagram} alt="" className="media-icon"/></a>
        </div>
    </div>
</div>
</footer>
</>
    )
}